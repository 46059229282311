// import 'swiper';
/**
 * We'll load jQuery, bootstrap and slick carousel
 */

try {
    //if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
    var MQL = 300;
    //primary navigation slide-in effect
    if($(window).width() > MQL) {
        var headerHeight = $('.cd-header').height();
        $(window).on('scroll',
        {
            previousTop: 0
        },
        function () {
            var currentTop = $(window).scrollTop();
            //check if user is scrolling up
            if (currentTop < this.previousTop ) {
                //if scrolling up...
                if (currentTop > 0 && $('.cd-header').hasClass('is-fixed')) {
                    $('.cd-header').addClass('is-visible');
                } else {
                    $('.cd-header').removeClass('is-visible is-fixed');
                }
            } else {
                //if scrolling down...
                $('.cd-header').removeClass('is-visible');
                if( currentTop > headerHeight && !$('.cd-header').hasClass('is-fixed')) $('.cd-header').addClass('is-fixed');
            }
            this.previousTop = currentTop;
        });
    }

    /** PLAY VIDEO **/

    // // global variable for the player
    // var player;

    // // this function gets called when API is ready to use
    // function onYouTubePlayerAPIReady() {
    //   // create the global player from the specific iframe (#video)
    //   player = new YT.Player('video', {
    //     events: {
    //       // call this function when player is ready to use
    //       'onReady': onPlayerReady
    //     }
    //   });
    // }

    // function onPlayerReady(event) {

    //   // bind events
    //   var playButton = document.getElementById("play-button");
    //   playButton.addEventListener("click", function() {
    //     player.playVideo();
    //   });

    //   var pauseButton = document.getElementById("pause-button");
    //   pauseButton.addEventListener("click", function() {
    //     player.pauseVideo();
    //   });

    // }

    // // Inject YouTube API script
    // var tag = document.createElement('script');
    // tag.src = "//www.youtube.com/player_api";
    // var firstScriptTag = document.getElementsByTagName('script')[0];
    // firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /** File upload file name **/
    $('input[type=file]').on('change', function(){

        var value = $(this).val();
        var index = value.lastIndexOf("\\");
        var filename = value.substr(index);
        var file = filename.replace('\\', '');

        $('div.appendArea').text(file);
    })


        $('#chat-tab-button').on('click', function (e) {
            $(this).parent().toggleClass('open');
            e.preventDefault();
        });

    $('body').delegate('#speak-to-us-form', 'submit', function (e) {
        e.preventDefault();
        var link = $(this).attr('action');
        var data = $(this).serialize() + '&js_handled=true';
        var title = $(this).data('title');

        if (! link) {
            return false;
        }

        $.post(link, data, function(data){
            $('.chat-tab-popout').removeClass('open');
            $('#modalBody').html(data);
            $('#modalLabel').html(title);
            $('#modal').modal();
            captchaCallback();
        });
        return false;
    });

        $('#employed-popout-button').on('click', function (e) {
            $(this).parent().toggleClass('open');
            e.preventDefault();
        });

    $('body').delegate('#employed-popout-form', 'submit', function (e) {
        e.preventDefault();
        var link = $(this).attr('action');
        var data = $(this).serialize() + '&js_handled=true';
        var title = $(this).data('title');

        if (! link) {
            return false;
        }

        $.post(link, data, function(data){
            $('.chat-tab-popout').removeClass('open');
            $('#modalBody').html(data);
            $('#modalLabel').html(title);
            $('#modal').modal();
            captchaCallback();
        });
        return false;
    });

} catch (e) {}